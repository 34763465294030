import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Smart Home App Template | Smart Home App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/smart-home-mobile-app/"
    metaDescription="Design your own smart home app today with Homelogy, our smart home mobile app design template. Try now with Uizard Pro and bring your idea to life!"
    description="
    h2:Check out Homelogy, our smart home app design template for mobile!
    <br/>
    Working on a smart home app design? Our brand-new <a:https://uizard.io/templates/>UI design template</a>, Homelogy, could be the perfect solution to streamline your design. Packed with all the features you would expect from a smart home mobile app, the homology template is ready to go right out of the box. Simply sign up for Uizard Pro, open the template, and adapt it to suit your vision.
    <br/>
    h3:Smart home app design made easy
    <br/>
    Whether you're a pro when it comes to all things <a:https://uizard.io/wireframing/>wireframing</a> and prototyping or you're a complete newcomer, Uizard templates are here to streamline your design experience. Our templates are ready to go right out of the box, but if you want to adapt the design to suit your own brand, updating and adding extra elements or screens is super easy with our drag-and-drop editor.
    <br/>
    h3:Prototype with your team today
    <br/>
    Uizard also supports easy collaboration with your team. Whether you are working in a team of 3 or you have multiple stakeholders you need to get sign-off from, you can easily share your project with our smart sharing features.
    "
    pages={[
      "A streamlined entry screen with a funky aesthetic",
      "Add device screen for multiple devices including lighting and temperature",
      "A multi-room home screen for multiple device control",
      "User screen with family control options",
    ]}
    projectCode="8oJp4wEl5VTLoBXLJBVl"
    img1={data.image1.childImageSharp}
    img1alt="smart home mobile app cover screen"
    img2={data.image2.childImageSharp}
    img2alt="smart home mobile app homepage"
    img3={data.image3.childImageSharp}
    img3alt="smart home mobile app room screen"
    img4={data.image4.childImageSharp}
    img4alt="smart home mobile app stats screen"
    img5={data.image5.childImageSharp}
    img5alt="smart home mobile app cover summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/smart-home-mobile-app/smart-home-mobile-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/smart-home-mobile-app/smart-home-mobile-homepage.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/smart-home-mobile-app/smart-home-mobile-room.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/smart-home-mobile-app/smart-home-mobile-stats.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/smart-home-mobile-app/smart-home-mobile-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
